import { differenceInSeconds } from 'date-fns';
import { DEFAULT_LANGUAGE, ROWS_PER_PAGE } from './constant';
import * as XLSX from 'xlsx';

export const convertHexToRGB = (hex) => {
  // check if it's a rgba
  if (hex.match('rgba')) {
    let triplet = hex.slice(5).split(',').slice(0, -1).join(',');
    return triplet;
  }

  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');

    return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
  }
};

export const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

export function isMobile() {
  if (window) {
    return window.matchMedia(`(max-width: 767px)`).matches;
  }
  return false;
}

export function isMdScreen() {
  if (window) {
    return window.matchMedia(`(max-width: 1199px)`).matches;
  }
  return false;
}

function currentYPosition(elm) {
  if (!window && !elm) {
    return;
  }
  if (elm) return elm.scrollTop;
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) return window.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
    return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
}

function elmYPosition(elm) {
  var y = elm.offsetTop;
  var node = elm;
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  }
  return y;
}

export function scrollTo(scrollableElement, elmID) {
  var elm = document.getElementById(elmID);

  if (!elmID || !elm) {
    return;
  }

  var startY = currentYPosition(scrollableElement);
  var stopY = elmYPosition(elm);

  var distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
    scrollTo(0, stopY);
    return;
  }
  var speed = Math.round(distance / 50);
  if (speed >= 20) speed = 20;
  var step = Math.round(distance / 25);
  var leapY = stopY > startY ? startY + step : startY - step;
  var timer = 0;
  if (stopY > startY) {
    for (var i = startY; i < stopY; i += step) {
      setTimeout(
        (function (leapY) {
          return () => {
            scrollableElement.scrollTo(0, leapY);
          };
        })(leapY),
        timer * speed
      );
      leapY += step;
      if (leapY > stopY) leapY = stopY;
      timer++;
    }
    return;
  }
  for (let i = startY; i > stopY; i -= step) {
    setTimeout(
      (function (leapY) {
        return () => {
          scrollableElement.scrollTo(0, leapY);
        };
      })(leapY),
      timer * speed
    );
    leapY -= step;
    if (leapY < stopY) leapY = stopY;
    timer++;
  }
  return false;
}

export function getTimeDifference(date) {
  let difference = differenceInSeconds(new Date(), date);

  if (difference < 60) return `${Math.floor(difference)} sec`;
  else if (difference < 3600) return `${Math.floor(difference / 60)} min`;
  else if (difference < 86400) return `${Math.floor(difference / 3660)} h`;
  else if (difference < 86400 * 30) return `${Math.floor(difference / 86400)} d`;
  else if (difference < 86400 * 30 * 12) return `${Math.floor(difference / 86400 / 30)} mon`;
  else return `${(difference / 86400 / 30 / 12).toFixed(1)} y`;
}

export function generateRandomId() {
  let tempId = Math.random().toString();
  let uid = tempId.substr(2, tempId.length - 1);
  return uid;
}

export function getQueryParam(prop) {
  var params = {};
  var search = decodeURIComponent(
    window.location.href.slice(window.location.href.indexOf('?') + 1)
  );
  var definitions = search.split('&');
  definitions.forEach(function (val, key) {
    var parts = val.split('=', 2);
    params[parts[0]] = parts[1];
  });
  return prop && prop in params ? params[prop] : params;
}

export function classList(classes) {
  return Object.entries(classes)
    .filter((entry) => entry[1])
    .map((entry) => entry[0])
    .join(' ');
}

export const flat = (array) => {
  var result = [];
  array.forEach(function (a) {
    result.push(a);
    if (Array.isArray(a.children)) {
      result = result.concat(flat(a.children));
    }
  });
  return result;
};

export function filterArrayKey(originalArray, keysToDisplay) {
  return originalArray.map(obj => {
    const filteredObject = {};
    keysToDisplay.forEach(key => {
      if (obj.hasOwnProperty(key)) {
        filteredObject[key] = obj[key];
      }
    });
    return filteredObject;
  });
}

export const smoothScrollError = (errClass = '.Mui-error') => {
  setTimeout(() => {
    const invalidField = document.querySelector(errClass);
    if (invalidField) {
      invalidField.scrollIntoView({ behavior: 'smooth', block: 'center' });
      invalidField.focus();
    }
  }, 500);
}

export const getSortingOrder = (orderInNumbersColumns = [], order = '', orderBy = '', DEFAULT_ASC_DESC = []) => {
  // important if column value is in numbers for ASC=0 DESC=1
  const index = orderInNumbersColumns?.indexOf(orderBy);
  if ((orderInNumbersColumns?.length > 0) && index > -1) {
    const replaceValue = DEFAULT_ASC_DESC?.length > 0 ? DEFAULT_ASC_DESC[index] : { ASC: 0, DESC: 1 };
    return order === 'asc' ? replaceValue['ASC'] : replaceValue['DESC'];
  }
  return order?.toUpperCase()
}
export const defaultDateTimeFormat = (input) => {
  const date = new Date(input);

  // Check if the input is a valid date
  if (isNaN(date.getTime())) {
    return null;
  }

  // Extract date components in UTC
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = date.getUTCFullYear();

  // Extract time components in UTC
  let hours = date.getUTCHours();
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'
  const formattedHours = String(hours).padStart(2, '0');

  // Construct the final formatted date-time string
  const formattedDateTime = `${day}-${month}-${year} ${formattedHours}:${minutes} ${ampm} GMT`;

  return formattedDateTime;
};

export const rowsPerPageOptions = (total) => {
  return (total < ROWS_PER_PAGE[0]) ? [0] : ROWS_PER_PAGE;
}

export const capitalizeFirstLetter = (str) => {
  if (!str) return ''; // Handle empty or undefined strings
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const getInitLang = () => {
  const storedLocale = localStorage.getItem('locale');
  if (storedLocale) {
    return storedLocale
  }
  return DEFAULT_LANGUAGE;
}

export const formatDateToCustom = (dateString) => {
  const date = new Date(dateString);

  // Extract hours, minutes, and seconds
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  // Determine AM or PM
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert 24-hour time to 12-hour time
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Format minutes and seconds to always have two digits
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
  const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;

  // Return the formatted time string
  return `${hours}:${formattedMinutes}:${formattedSeconds} ${ampm} GMT`;
}

export const isObjectNullOrEmpty = (obj) => {
  if (obj == null) {
    return true;
  }
  return Object.keys(obj).every(key => obj[key] === '');
}

export const formatTimestampToGMT = (timestamp) => {
  const date = new Date(`${timestamp}Z`);

  // Extract the date components
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getUTCFullYear();

  // Format the time in GMT using UTC methods
  const hours = date.getUTCHours();
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  const period = hours >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Combine the formatted date and time
  const formattedDate = `${day}-${month}-${year}`;
  const formattedTime = `${formattedHours}:${minutes}:${seconds} ${period} GMT`;

  return {
    day: day,
    month: month,
    year: year,
    hours: hours,
    seconds: seconds,
    period: period,
    hours: hours,
    date: formattedDate,
    time: formattedTime
  };
}

export const formatDateYYYYMMDD = (date, del = '-') => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(d.getDate()).padStart(2, '0');
  return `${year}${del}${month}${del}${day}`;
};

// This method use in every where please don't change
export const formatDateDDMMYYYY = (date, del = '-') => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(d.getDate()).padStart(2, '0');
  return `${day}${del}${month}${del}${year}`;
};

export const downloadExcel = (tableData = [], fileName = "Report") => {
  const worksheet = XLSX.utils.json_to_sheet(tableData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.xlsx`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const objToUrlParams = (obj) => {
  return Object.keys(obj).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`).join('&');
}