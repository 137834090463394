export const navigations = [
  { name: 'Dashboard', path: '/dashboard/default', icon: 'dashboard' },
  {
    name: 'Marketplace',
    icon: 'store',
    children: [
      { name: 'Vendors', path: '/marketplace/vendors' },
      { name: 'Product Reviews', path: '/marketplace/product-reviews' },
      { name: 'Vendor Reviews', path: '/marketplace/vendor-reviews' },
      { name: 'Orders', path: '/marketplace/orders' },
      { name: 'Transactions', path: '/marketplace/transactions' },
      { name: 'Vendor Flag Reason', path: '/marketplace/vendor-flag' },
      { name: 'Product Flag Reason', path: '/marketplace/product-flag' },
      { name: 'Vendor Categories', path: '/marketplace/vendor-categories' },
      { name: 'Payment Requests', path: '/marketplace/payment-requests' },
    ],
  },
  {
    name: 'Sales',
    icon: 'equalizer ',
    children: [
      { name: 'Orders', iconText: 'OR', path: '/sales/orders' },
      { name: 'Shipments', iconText: 'S', path: '/sales/shipments' },
      { name: 'Invoices', iconText: 'OR', path: '/sales/invoices' },
      { name: 'Refunds', iconText: 'OR', path: '/sales/refunds' },
      { name: 'Order Transactions', iconText: 'OR', path: '/sales/transactions' },
      { name: 'Bookings', iconText: 'OR', path: '/sales/bookings' },
    ],
  },
  {
    name: 'Catalog',
    icon: 'storage',
    children: [
      { name: 'Products', path: '/catalog/products' },
      { name: 'Categories', path: '/catalog/categories' },
      { name: 'Attributes', path: '/catalog/attributes' },
      { name: 'Attribute Families', path: '/catalog/attributefamilies' },
    ],
  },
  {
    name: 'Customers',
    icon: 'group',
    children: [
      { name: 'Customers List', path: '/users/customers' },
      { name: 'Groups', path: '/users/groups' },
      { name: 'Reviews', path: '/users/reviews' },
    ],
  },
  {
    name: 'Marketing',
    icon: 'campaign',
    children: [
      { name: 'Email Marketing', path: '/marketing/email-marketing' },
      { name: 'Promotions', path: '/marketing/promotions' }
    ],
  },
  {
    name: 'CMS',
    icon: 'settings_brightness',
    children: [
      { name: 'Pages', path: '/cms/pages' },
      { name: 'FAQs', path: '/cms/FAQ' },
      { name: 'Media', path: '/cms/media' }
    ],
  },
  {
    name: 'Enquiry',
    icon: 'question_answer',
    children: [
      { name: 'Seller Product', path: '/enquiry/seller-products' },
      { name: 'User Enquiry', path: '/enquiry/user-enquiry' }
    ],
  },
  {
    name: 'Reports', icon: 'assessment',
    children: [
      { name: 'Sales Report', iconText: 'build', path: '/reports/sales' },
      { name: 'Customer Report', iconText: 'build', path: '/reports/customers' },
      { name: 'Products Report', iconText: 'build', path: '/reports/products' },
    ]
  },
  {
    name: 'Settings',
    icon: 'settings',
    children: [
      { name: 'Locales', iconText: 'LG', path: '/settings/locales' },
      { name: 'Currencies', iconText: 'CR', path: '/settings/currencies' },
      { name: 'Exchange Rates', iconText: 'ER', path: '/settings/exchange-rates' },
      { name: 'Inventory Sources', iconText: 'IS', path: '/settings/inventory-sources' },
      { name: 'Channels', iconText: 'IS', path: '/settings/channels' },
      { name: 'Taxes', iconText: 'TX', path: '/settings/taxes' },
      { name: 'Users', iconText: 'U', path: '/settings/users',},
      { name: 'Sliders', iconText: 'U', path: '/settings/sliders' },
      { name: 'Themes', iconText: 'Th', path: '/settings/themes' },
      // { name: 'AutoMarto Theme', iconText: 'AT', path: '/settings/automarto-theme' },
      { name: 'Menu Management', iconText: 'AT', path: '/settings/menu-management' },
      { name: 'Admin Menu Management', iconText: 'AT', path: '/settings/admin-menu-management' },
    ],
  },
  {
    name: 'Configure', icon: 'build', path: '/views/configure',
    children: [
      { name: 'System Configure', iconText: 'build', path: '/views/configure' },
    ]
  },
];
