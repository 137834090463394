import React, { createContext, useEffect, useState } from 'react'

import { merge } from 'lodash'

import { MatxLayoutSettings } from 'app/components/MatxLayout/settings'
import axios from 'axios'

const SettingsContext = createContext({
    settings: MatxLayoutSettings,
    updateSettings: () => { },
})

export const SettingsProvider = ({ settings, children }) => {
    const localeBaseUrl = process.env.REACT_APP_API_LOCALE_BASE_URL;
    const getAdminConfig = async () => {
        try {
            const res = await axios.get(`${localeBaseUrl}/admin-config`);
            if (res?.data?.status[0]?.code==="200") {
                const resData = res?.data?.response?.CoreConfig[0]; 
                handleUpdateSettings({favicon:resData?.favicon, logo:resData?.logo});
            }
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        // load logo images
        getAdminConfig();  
    }, [])

    const [currentSettings, setCurrentSettings] = useState(
        settings || MatxLayoutSettings
    )

    const handleUpdateSettings = (update = {}) => {
        const marged = merge({}, currentSettings, update)
        setCurrentSettings(marged)
    }

    return (
        <SettingsContext.Provider
            value={{
                settings: currentSettings,
                updateSettings: handleUpdateSettings,
            }}
        >
            {children}
        </SettingsContext.Provider>
    )
}

export default SettingsContext
